$(function() {

	var init = function(){
		$('[data-slick]').slick();
		$('.meetthefleet-carousel').slick({
			autoplay: true,
			dots: false,
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1024,
					// settings: 'unslick'
					settings: {
						autoplay: false,
						arrows: true,
						slidesToShow: 5,
						slidesToScroll: 5
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}
			]
		});

		$( "header.main .mobilenav" ).on( "click", function() {
			$( "#nav-main" ).toggleClass( "open" );
			$( "header.main .login" ).toggleClass( "open" );
		});
	}

    init();

});

